export default class Modal {
    constructor() {
        this.onReady = this.onReady.bind(this);
    }

    init() {
        document.addEventListener('DOMContentLoaded', () => {
            this.onReady();
        });
    }

    onReady() {
        const self = this;
        $(document).on('click', 'a[href*="#arkon-popup"]', function (e) {
            e.preventDefault();
            const id = e.target.href
                .substring(e.target.href.indexOf('#arkon-popup'))
                .trim();

            self.show(id);
        });

        $(document).on('click', '.arkon-popups-modal', function (e) {
            if (!e.target.classList.contains('arkon-popups-modal')) {
                return;
            }

            e.target.classList.remove('active');
        });

        $(document).on('click', '.arkon-popups-modal__close', function (e) {
            $(this).closest('.arkon-popups-modal').removeClass('active');
        });
    }

    show(id) {
        const popup = $(id);

        if (!popup.length) {
            console.error('Error: Invalid popup ID');
            return;
        }

        popup.addClass('active');
    }
}
